import React from "react"
import Layout from "../components/layout";
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser"
import EmailSubscribe from "../components/email-subscribe";

const SimpleTemplate = ({ data: { post }}) => {

  const image = getImage(post.featuredImage.node.localFile.childImageSharp.gatsbyImageData)
  const seoImage = post.featuredImage.node.localFile.childImageSharp.fluid.src
  const title = post.seo && post.seo.title ? post.seo.title : 'Carbon Switch'
  const description = post.seo && post.seo.metaDesc ? post.seo.metaDesc : 'We produce research and guides that help people and communities live more sustainably'

  return (
    <Layout
      title={title}
      description={description}
      image={seoImage}
    >
      <div className="mt-40">
        <article className="max-w-2xl mx-auto px-4 mb-20">
        <div>
          <header>
            <h1>{parse(post.title)}</h1>
            <p className="mt-6 font-bold text-slate-800">By&nbsp;
              <Link className="text-slate-800" to={post.author.node.uri}>
              {post.author.node.name}
              </Link>
            </p>
            <GatsbyImage image={image} alt={parse(post.title)} className="mb-10 mt-6" />
          </header>
          <section>
            {parse(post.content)}
          </section>
        </div>
        </article> 

        <div id="newsletter-cta" className='bg-slate-100 w-full mt-20'>
          <div className="text-left sm:text-center flex flex-col mt-12 pt-6 pb-12 px-5 sm:px-3 md:px-6 lg:px-12">
            <div className="mx-auto max-w-2xl">
              <h3>Sign up to receive more stories like this in your inbox</h3>
              <p>
                Every week we write a newsletter with stories just like this. 
                We cover every thing from home energy to electrification to climate change. 
                Not sure if you'll like it? Check out&nbsp;
                <Link to="/newsletter/">our most recent stories here.</Link>
              </p>
            </div>
            <EmailSubscribe />
          </div>
        </div>

      </div>

    </Layout>
  )

}

export default SimpleTemplate

export const pageQuery = graphql`
  query GetById($id: String!) {
    post: wpPost(id: {eq: $id}) {
      id
      content
      title
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
              fluid {
                src
              }
            }
          }
        }
      }
      seo {
        title
        metaDesc
      }
      author {
        node {
          uri
          name
        }
      }
    }
  }
`